import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import documentsReducer from './documentsSlice';
import notificationsReducer from './notificationsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    documents: documentsReducer,
    notifications: notificationsReducer,
  },
});