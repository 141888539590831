import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { forgotPassword, resetPassword } from '../Helpers/api';

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Check if a token is in the URL

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  // Handle forgot password email request
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      const response = await forgotPassword(email);
      setMessage(response.msg || 'If an account with that email exists, a reset link has been sent.');
    } catch (err) {
      setError(err.response?.data?.msg || 'An error occurred. Please try again.');
    }
  };

// Handle password reset
const handleResetPassword = async (e) => {
  e.preventDefault();
  if (newPassword !== confirmPassword) {
    setError('Passwords do not match.');
    return;
  }

  setMessage('');
  setError('');
  try {
    const response = await resetPassword({ token, newPassword });
    setMessage(response.msg || 'Password reset successful. You can now log in.');
  } catch (err) {
    const errorMsg = err.response?.data?.msg;

    // Check for specific token errors
    if (errorMsg?.toLowerCase().includes('token expired')) {
      setError('This reset link has expired. Please request a new one.');
    } else if (errorMsg?.toLowerCase().includes('invalid token')) {
      setError('Invalid reset link. Please check your email for the correct link or request a new one.');
    } else {
      setError(errorMsg || 'An error occurred. Please try again.');
    }
  }
};

  return (
    <div style={{ maxWidth: '400px', margin: 'auto', textAlign: 'center' }}>
      <h1>{token ? 'Reset Password' : 'Forgot Password'}</h1>

      {/* If token exists, show reset password form */}
      {token ? (
        <form onSubmit={handleResetPassword}>
          <div>
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', margin: '10px 0' }}
            />
          </div>
          <div>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', margin: '10px 0' }}
            />
          </div>
          <button type="submit" style={{ padding: '10px 20px' }}>
            Reset Password
          </button>
        </form>
      ) : (
        // If no token, show forgot password form
        <form onSubmit={handleForgotPassword}>
          <div>
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', margin: '10px 0' }}
            />
          </div>
          <button type="submit" style={{ padding: '10px 20px' }}>
            Send Reset Link
          </button>
        </form>
      )}

      {/* Display messages */}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default ForgotPassword;
