import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Button, Typography, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { logout } from './components/redux/authSlice';

// Existing Components
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import VerifyEmail from './components/Auth/VerifyEmail';
import ForgotPassword from './components/Auth/ForgotPassword';
import Dashboard from './components/Dashboard';
import DocumentManagement from './components/DocumentManagement';
import Notifications from './components/Notifications';
import ProfileSettings from './components/ProfileSettings';

function App() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Router>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Compass
            </Typography>
            {isAuthenticated ? (
              <>
                <Button color="inherit" component={Link} to="/" startIcon={<DashboardIcon />}>
                  Dashboard
                </Button>
                <Button color="inherit" component={Link} to="/documents" startIcon={<DescriptionIcon />}>
                  Documents
                </Button>
                <Button color="inherit" component={Link} to="/benefits" startIcon={<AccountBalanceIcon />}>
                  Benefits
                </Button>
                <Button color="inherit" component={Link} to="/notifications" startIcon={<NotificationsIcon />}>
                  Notifications
                </Button>
                <Button color="inherit" component={Link} to="/profile" startIcon={<PersonIcon />}>
                  Profile
                </Button>
                <Button color="inherit" onClick={handleLogout} startIcon={<ExitToAppIcon />}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/login">Login</Button>
                <Button color="inherit" component={Link} to="/register">Register</Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        <Routes>
          {/* Auth Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          
          {/* Main Routes */}
          <Route 
            path="/" 
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/documents" 
            element={isAuthenticated ? <DocumentManagement /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/notifications" 
            element={isAuthenticated ? <Notifications /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/profile" 
            element={isAuthenticated ? <ProfileSettings /> : <Navigate to="/login" />} 
          />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;