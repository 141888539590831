import React, { useEffect } from 'react';
import { Container, Typography, Grid, Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';

const Dashboard = () => {
  const navigate = useNavigate();

  // Get authentication state from Redux
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      // Redirect to login if not authenticated
      navigate('/login');
    }
  }, [isAuthenticated, loading, navigate]);

  const sections = [
    { title: 'Document Management', icon: <DescriptionIcon fontSize="large" />, path: '/documents', description: 'Access and manage your document management' },
    { title: 'Notifications', icon: <NotificationsIcon fontSize="large" />, path: '/notifications', description: 'Access and manage your notifications' },
    { title: 'Profile Settings', icon: <PersonIcon fontSize="large" />, path: '/profile', description: 'Access and manage your profile settings' },
  ];

  if (loading) {
    // Loading Graphics of some kind
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h5">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Military Estate Planning Dashboard
      </Typography>
      <Grid container spacing={3}>
        {sections.map((section) => (
          <Grid item xs={12} sm={6} md={3} key={section.title}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
                cursor: 'pointer',
                transition: '0.3s',
                '&:hover': {
                  boxShadow: 6,
                  transform: 'scale(1.02)',
                },
              }}
              onClick={() => navigate(section.path)}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                {section.icon}
              </Box>
              <Typography variant="h6" component="h2" gutterBottom align="center">
                {section.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                {section.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
