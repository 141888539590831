// src/components/redux/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../Helpers/api';

// Async thunk for user login
export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.loginUser(credentials); // Using api.js for loginUser
      return response; // Backend may return { user, twoFactorRequired }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Login failed');
    }
  }
);

// Async thunk for user registration
export const registerUser = createAsyncThunk(
  'auth/register',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await api.registerUser(userData); // Using api.js for registerUser
      return response; // Assuming backend returns user data
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Registration failed');
    }
  }
);

// Async thunk for verifying 2FA
export const verifyTwoFactorAuth = createAsyncThunk(
  'auth/verifyTwoFactorAuth',
  async ({ email, password, twoFactorCode }, { rejectWithValue }) => {
    try {
      const response = await api.loginUser({ email, password, twoFactorCode }); // Using api.js for 2FA verification
      return response; // Assuming successful login returns user data
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || '2FA verification failed');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isAuthenticated = !action.payload.twoFactorRequired; // Set authenticated if 2FA not required
        state.user = action.payload.user || null; // Set user data if available
        state.loading = false;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle user registration
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isAuthenticated = true; // Automatically log in after registration
        state.user = action.payload.user; // Set user data if available
        state.loading = false;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle 2FA verification
      .addCase(verifyTwoFactorAuth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyTwoFactorAuth.fulfilled, (state, action) => {
        state.isAuthenticated = true; // Mark as authenticated after successful 2FA
        state.user = action.payload.user; // Set user data
        state.loading = false;
        state.error = null;
      })
      .addCase(verifyTwoFactorAuth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, clearError } = authSlice.actions;
export default authSlice.reducer;
