// src/components/Auth/Login.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, verifyTwoFactorAuth } from '../redux/authSlice'; // Import actions
import { useNavigate } from 'react-router-dom';
import { Container, Paper, TextField, Button, Typography, Alert } from '@mui/material';

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleTwoFactorChange = (e) => {
    setTwoFactorCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(loginUser(credentials))
      .unwrap()
      .then((response) => {
        if (response.twoFactorRequired) {
          setIsTwoFactorRequired(true);
        } else {
          navigate('/');
        }
      })
      .catch((err) => {
        console.error('Login failed:', err);
      });
  };

  const handleTwoFactorSubmit = (e) => {
    e.preventDefault();

    dispatch(verifyTwoFactorAuth({ ...credentials, twoFactorCode }))
      .unwrap()
      .then(() => {
        navigate('/');
      })
      .catch((err) => {
        console.error('2FA verification failed:', err);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {isTwoFactorRequired ? (
          <form onSubmit={handleTwoFactorSubmit} style={{ width: '100%', mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="twoFactorCode"
              label="Two-Factor Authentication Code"
              value={twoFactorCode}
              onChange={handleTwoFactorChange}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? 'Verifying...' : 'Verify'}</Button>
          </form>
        ) : (
          <form onSubmit={handleSubmit} style={{ width: '100%', mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={credentials.password}
              onChange={handleChange}
            />
            <Typography
            variant="body2"
            sx={{ mt: 2, textAlign: 'center', cursor: 'pointer' }}
            color="primary"
            onClick={() => navigate('/forgot-password')}
            >
            Forgot Password?
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}</Button>
          </form>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Paper>
    </Container>
  );
};

export default Login;
