import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { verifyEmail } from '../Helpers/api'; // Import the API function

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Check if a token is in the URL

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (token) {
      // Automatically verify the email when the token is present
      const verify = async () => {
        setMessage('');
        setError('');
        try {
          const response = await verifyEmail(token);
          setMessage(response.msg || 'Email verified successfully!');
        } catch (err) {
          setError(err.response?.data?.msg || 'Verification failed. Please try again.');
        }
      };

      verify();
    }
  }, [token]);

  return (
    <div style={{ maxWidth: '400px', margin: 'auto', textAlign: 'center' }}>
      <h1>Email Verification</h1>

      {/* Show success or error message based on token processing */}
      {token && (message || error) && (
        <div>
          {message && <p style={{ color: 'green' }}>{message}</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      )}

      {/* If no token, prompt the user to navigate back */}
      {!token && (
        <p>
          Please check your email for the verification link. If you haven't received it, try
          registering again or contacting support.
        </p>
      )}
    </div>
  );
};

export default VerifyEmail;
