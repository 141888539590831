import React, { useState, useCallback } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  Box,
  TextField,
  CircularProgress,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Alert,
  ListItemIcon
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';

const DocumentManagement = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [customFileName, setCustomFileName] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [documents, setDocuments] = useState([]);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf,.doc,.docx',
    maxSize: 5 * 1024 * 1024
  });

  const handleUpload = () => {
    if (!selectedFile || !category || !customFileName) {
      setSnackbar({ open: true, message: 'Please complete all fields and select a file.', severity: 'error' });
      return;
    }

    const newDocument = {
      id: documents.length + 1,
      fileName: customFileName,
      category,
      description,
    };

    setDocuments([...documents, newDocument]);
    setSnackbar({ open: true, message: 'Document uploaded successfully!', severity: 'success' });

    setSelectedFile(null);
    setCustomFileName('');
    setCategory('');
    setDescription('');
  };

  const handleOpenFileDialog = (document) => {
    setSelectedDocument(document);
    setOpenFileDialog(true);
  };

  const handleCloseFileDialog = () => {
    setOpenFileDialog(false);
    setSelectedDocument(null);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Document Management
      </Typography>

      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            p: 2,
            mb: 2,
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': { borderColor: 'primary.main' },
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? <Typography>Drop the files here...</Typography> : <Typography>Drag and drop files here, or click to select</Typography>}
        </Box>
        {selectedFile && <Typography variant="body2" sx={{ mb: 2 }}>Selected file: {selectedFile.name}</Typography>}

        <TextField
          label="Custom File Name"
          value={customFileName}
          onChange={(e) => setCustomFileName(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={3}
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!selectedFile}
          startIcon={<CloudUploadIcon />}
        >
          Upload
        </Button>
      </Paper>

      <Paper elevation={3} sx={{ p: 2 }}>
        <List>
          {documents.map((doc) => (
            <ListItem key={doc.id} button onClick={() => handleOpenFileDialog(doc)}>
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText
                primary={doc.fileName}
                secondary={`${doc.category} - ${doc.description}`}
              />
            </ListItem>
          ))}
        </List>
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={openFileDialog} onClose={handleCloseFileDialog} fullWidth maxWidth="lg">
        <DialogTitle>
          View Document
          <IconButton
            aria-label="close"
            onClick={handleCloseFileDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedDocument ? (
            <Typography>{`File: ${selectedDocument.fileName}`}</Typography>
          ) : (
            <Typography>Loading document...</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default DocumentManagement;
