// /Helpers/api.js - Axios instance with common configuration and API functions

import axios from 'axios';

const BASE_URL = 'https://api.truenorthlegacy.org';

// Function to register a user
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/register`, userData, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data; // Return response message or data
  } catch (error) {
    console.error(error.response ? error.response.data : error.message);
    throw error; // Rethrow error for caller to handle
  }
};

// Function to log in a user
export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, credentials, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data; // Return token or additional login info
  } catch (error) {
    console.error(error.response ? error.response.data : error.message);
    throw error; // Rethrow error for caller to handle
  }
};

// Function to verify email
export const verifyEmail = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/auth/verify-email`, {
      params: { token },
    });
    return response.data; // Return response message
  } catch (error) {
    console.error(error.response ? error.response.data : error.message);
    throw error; // Rethrow error for caller to handle
  }
};

// Function to initiate password reset
export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/forgot-password`, { email }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data; // Return response message
  } catch (error) {
    console.error(error.response ? error.response.data : error.message);
    throw error; // Rethrow error for caller to handle
  }
};

// Function to reset password
export const resetPassword = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/reset-password`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data; // Return response message
  } catch (error) {
    console.error(error.response ? error.response.data : error.message);
    throw error; // Rethrow error for caller to handle
  }
};

// Function to toggle two-factor authentication
export const toggleTwoFactorAuth = async (enable2FA) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/two-factor-auth`, { enable2FA }, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true, // Ensures cookies (JWT) are included
    });
    return response.data; // Return response message
  } catch (error) {
    console.error(error.response ? error.response.data : error.message);
    throw error; // Rethrow error for caller to handle
  }
};

// Function to update user profile
export const updateUserProfile = async (profileData) => {
  try {
    const response = await axios.put(`${BASE_URL}/auth/profile`, profileData, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true, // Ensures cookies (JWT) are included
    });
    return response.data; // Return updated profile
  } catch (error) {
    console.error(error.response ? error.response.data : error.message);
    throw error; // Rethrow error for caller to handle
  }
};

//PLACEHOLDERS FOR LATER

export const getBenefits = async () => {
  // Mock implementation
  return Promise.resolve({ data: [] }); // or mock data
};

export const fetchDocumentsAPI = async () => {
  // Mock implementation
  return Promise.resolve({ data: [] });
};

export const uploadDocumentAPI = async () => {
  // Mock implementation
  return Promise.resolve({ message: "Document uploaded" });
};

export const fetchDocumentSecureUrlAPI = async () => {
  // Mock implementation
  return Promise.resolve({ url: "https://example.com/secure-url" });
};

export const deleteDocumentAPI = async () => {
  // Mock implementation
  return Promise.resolve({ message: "Document deleted" });
};

export const getNotifications = async () => {
  // Mock implementation
  return Promise.resolve({ data: [] });
};

export default {
  registerUser,
  loginUser,
  verifyEmail,
  forgotPassword,
  resetPassword,
  toggleTwoFactorAuth,
  updateUserProfile,
  
};